import React, { ReactElement } from 'react';
import { Helmet } from 'react-helmet';
// import { useSelector } from 'react-redux';
import { createStyles, Grid, StyleRules, WithStyles, withStyles } from '@material-ui/core';
// import { navigate } from 'gatsby';
// import { AppState } from '../../reducers';

const styles = (): StyleRules =>
  createStyles({
    contentPadding: {
      minHeight: '80vh',
      padding: '20px',
    },
    childrenItem: {
      background: '#ffffff',
      boxShadow: '0 2px 4px rgba(0,0,0,0.3)',
      borderRadius: '3px',
      marginBottom: '10px',
    },
  });

const indexView = ({ classes }: Props): ReactElement => {
  //   const user = useSelector((appState: AppState) => appState.user);
  //   const company = useSelector((appState: AppState) => appState.company);
  //   useEffect(() => {
  //     if (user.status == 'NONE' || !user.status) {
  //       if (company.verification?.status === 'NONE' || !company.verification?.status) {
  //         navigate('/company/verification');
  //       }
  //     }
  //   }, []);

  return (
    <>
      <Helmet title="管理首页"></Helmet>
      <Grid container className={classes.contentPadding} justifyContent="space-between">
        <Grid item xs={12} className={classes.childrenItem}>
          <div>欢迎来到管理员首页</div>
        </Grid>
        <Grid item xs={3} className={classes.childrenItem}>
          发布需求的状态
        </Grid>
        <Grid item xs={3} className={classes.childrenItem}>
          进行中的项目
        </Grid>
        <Grid item xs={3} className={classes.childrenItem}>
          竞价统计
        </Grid>
        <Grid item xs={4} className={classes.childrenItem}>
          结算
        </Grid>
      </Grid>
    </>
  );
};

export interface Props extends WithStyles<typeof styles> {
  className?: string;
}

export default withStyles(styles)(indexView);
