import React from 'react';

import Layout from '../../layout/AdminLayout';
import IndexView from '../../views/admin/IndexView';

const IndexPage = (): React.ReactElement => {
  return (
    <Layout>
      <IndexView></IndexView>
    </Layout>
  );
};

export default IndexPage;
